import {makeAutoObservable} from 'mobx';
import * as Bean from './bean';
import ChatMessage from 'enum/ChatMessage';
import * as Api from "api/service";

/**
 * 客服 store
 */
class Service {
    show = false;
    message = '';
    messageList = [];


    async addMessage({message, userType}) {
        this.messageList.push(new Bean.Message(message, userType));
    }

    async sendMessage(message, callback) {
        try {
            this.message = '';

            const {answer} = await Api.askQuestion({question: message});

            this.addMessage({message: answer, userType: ChatMessage.TO});
            if (callback) {
                callback();
            }
        } catch (error) {
        }
    }

    init() {
        this.messageList.push(
            new Bean.Message('你好，有什么可以帮到你呢?', ChatMessage.TO)
        );
    }


    constructor() {
        makeAutoObservable(this)
    }
}

const service = new Service();

export default service;
